@charset "UTF-8";
@use "./setting" as *;
@use "./mixin" as *;

.pc-hidden {
  display: none;
  @include bpTb {
    display: inline-block;
  }
}

.sp-hidden {
  display: inline-block;
  @include bpTb {
    display: none;
  }
}

