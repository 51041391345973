@charset "UTF-8";

// color
$whiteColor: #fff;
$textColor: #212121;

$color-gray1: #ADADAD;
$color-gray2: #707070;
$color-gray3: #F0F0F0;
$color-blue1: #00BFFF;
$color-blue2: #1E90FF;
$color-blue3: #4169E1;
$color-blue4: #006EBC;
$color-pink1: #F51CD4;
$color-pink2: #F8C6C6;
$color-green1: #C6F8D5;
$color-black1: #000000;
$color-black2: #223557;
$color-black3: #3E3A39;

$color-gr1: linear-gradient(to right, #0081F0 0%, #6ED2FF 100%);
$color-gr2: linear-gradient(to right, #C7C5C5 0%,  #DBDBDB 100%);


// font
$baseFont: 15;
$baseFontSp: 14;
$baseWeight: 400;
$baseWeightBold: 700;
$lineHeight: 1.8;
$baseletter: 0.1em;
$mincho: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", serif;
$NotoSansJP: "Noto Sans JP","ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;

// font-size
$text-62: 62px;
$text-62-sp: 3.5rem;
$text-50: 50px;
$text-50-sp: 3rem;
$text-45: 45px;
$text-45-sp: 2.8rem;
$text-36: 36px;
$text-36-sp: 2.2rem;
$text-35: 35px;
$text-35-sp: 2rem;
$text-31: 31px;
$text-31-sp: 2rem;
$text-30: 30px;
$text-30-sp: 2rem;
$text-29: 29px;
$text-29-sp: 1.9rem;
$text-27: 27px;
$text-27-sp: 1.9rem;
$text-25: 25px;
$text-25-sp: 1.7rem;
$text-24: 24px;
$text-24-sp: 1.7rem;
$text-23: 23px;
$text-23-sp: 1.7rem;
$text-22: 22px;
$text-22-sp: 1.6rem;
$text-21: 21px;
$text-21-sp: 1.6rem;
$text-20: 20px;
$text-20-sp: 1.6rem;
$text-19: 19px;
$text-19-sp: 1.5rem;
$text-18: 18px;
$text-18-sp: 1.4rem;
$text-17: 17px;
$text-17-sp: 1.4rem;
$text-16: 16px;
$text-16-sp: 1.4rem;
$text-15: 15px;
$text-15-sp: 1.3rem;
$text-14: 14px;
$text-14-sp: 1.2rem;
$text-13: 13px;
$text-13-sp: 1.2rem;
$text-12: 12px;
$text-12-sp: 1.1rem;
$text-10: 10px;
$text-10-sp: 1rem;


// コンテンツ幅
$baseWidth: 1000px;
$baseWidthSp: 91.25%;
$baseWidthPadding: 4.375%;

// キャンバスサイズ
$maxWidth: 2000px;

// ブレークポイント
$bpPc: 1200px;
$bpPcs: 966px;
$bpTb: 768px;
$bpSp: 640px;
$bpSps: 425px;


@font-face {
    font-family: "DIN1451";
    src: url("../../fonts/din-1451-engschrift.otf") format("opentype");
}

@font-face {
    font-family: "arial-hebrew";
    src: url("../../fonts/arial-hebrew.ttf") format("truetype");
}