@charset "UTF-8";
@use "./setting" as *;
@use "sass:math";

@mixin fz($size: $baseFont) {
  font-size: $size + px;
  font-size: math.div($size, 16) + rem;
}

@mixin wrapper($width: 966px) {
  max-width: $width;
  margin-inline: auto;
  @include breakpoint($width) {
    margin-inline: $baseWidthPadding;
  }
}

@mixin wrapper2($width: 600px) {
  max-width: $width;
  margin-inline: auto;
  @include bpTb {
    margin-inline: $baseWidthPadding;
    max-width: none;
  }
}

@mixin breakpoint($width: $bpPcs) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin bpPc {
  @media screen and (max-width: $bpPc) {
    @content;
  }
}

@mixin bpPcs {
  @media screen and (max-width: $bpPcs) {
    @content;
  }
}


@mixin bpTb {
  @media screen and (max-width: $bpTb) {
    @content;
  }
}

@mixin bpSp {
  @media screen and (max-width: $bpSp) {
    @content;
  }
}

@mixin bpSps {
  @media screen and (max-width: $bpSps) {
    @content;
  }
}

@mixin iconFont() {
  font-family: "Font Awesome 5 Free", sans-serif;
  font-weight: 900;
  line-height: 1;
}

@mixin hover {
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @content;
    }
  }
}

// font(font-size, font-weight, line-height, letter-spacing)

@mixin font($size: null, $weight: null, $height: null, $spacing: null) {
  // @if $size {
  //   font-size: $size + px;
  //   font-size: math.div($size, 16) + rem;
  // }
  @if $weight {
    font-weight: $weight;
  }
  @if $height {
    line-height: calc($height / $size);
  }
  @if $spacing {
    letter-spacing: calc($spacing / 1000 * 1em);
  }
}
