@charset "UTF-8";
@font-face {
  font-family: "DIN1451";
  src: url("../../fonts/din-1451-engschrift.otf") format("opentype");
}
@font-face {
  font-family: "arial-hebrew";
  src: url("../../fonts/arial-hebrew.ttf") format("truetype");
}
.pc-hidden {
  display: none;
}
@media screen and (max-width: 768px) {
  .pc-hidden {
    display: inline-block;
  }
}

.sp-hidden {
  display: inline-block;
}
@media screen and (max-width: 768px) {
  .sp-hidden {
    display: none;
  }
}

/*-- globalフォルダの中の_index.scss --*/
.l-main#partner .partner .m-fv {
  background-image: url(../../img/partner/fv.jpg);
}
.l-main#partner .partner__area {
  padding-top: 150px;
  padding-bottom: 262px;
  background-color: #F0F0F0;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner__area {
    padding-top: 10rem;
    padding-bottom: 17.5rem;
  }
}
.l-main#partner .partner-news {
  padding-bottom: 160px;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-news {
    padding-bottom: 10rem;
  }
}
.l-main#partner .partner-news .partner-news__title {
  font-size: 27px;
  font-weight: 400;
  line-height: 1.8518518519;
  letter-spacing: 0.3em;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-news .partner-news__title {
    font-size: 1.9rem;
  }
}
.l-main#partner .partner-news .partner-news-list {
  margin-top: 40px;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-news .partner-news-list {
    margin-top: 4rem;
  }
}
.l-main#partner .partner-news .partner-news-list .partner-news-list__item:last-child .link {
  border-bottom: 0.1rem solid #fff;
}
.l-main#partner .partner-news .partner-news-list .link {
  display: flex;
  align-items: flex-start;
  padding: 23px 26px 28px;
  border-top: 0.1rem solid #fff;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-news .partner-news-list .link {
    display: block;
    padding: 1.5rem 1.5rem 2rem;
  }
}
.l-main#partner .partner-news .partner-news-list .partner-news-list__time {
  display: block;
  width: min(100%, 132px);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.05em;
  margin-top: 0.2em;
  color: #006EBC;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-news .partner-news-list .partner-news-list__time {
    width: auto;
    font-size: 1.2rem;
  }
}
.l-main#partner .partner-news .partner-news-list .partner-news-list__title {
  flex: 1;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-news .partner-news-list .partner-news-list__title {
    font-size: 1.4rem;
    margin-top: 0.5em;
  }
}
.l-main#partner .partner-news .partner-news__post {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.05em;
  text-align: center;
  margin-top: 5rem;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-news .partner-news__post {
    font-size: 1.4rem;
  }
}
.l-main#partner .partner-download .partner-download-list {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-top: 89px;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-download .partner-download-list {
    display: block;
    padding-top: 7rem;
  }
}
.l-main#partner .partner-download .partner-download-list .item {
  width: 29%;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-download .partner-download-list .item {
    width: 100%;
    margin-top: 5rem;
  }
}
.l-main#partner .partner-download .partner-download-list .item .item__head {
  font-size: 21px;
  font-weight: 400;
  line-height: 1.6666666667;
  letter-spacing: 0.3em;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-download .partner-download-list .item .item__head {
    font-size: 1.6rem;
  }
}
.l-main#partner .partner-download .partner-download-list .item .item__icon {
  text-align: center;
  margin-top: 52px;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-download .partner-download-list .item .item__icon {
    margin-top: 2rem;
  }
}
.l-main#partner .partner-download .partner-download-list .item .item-btn {
  margin-top: 36px;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-download .partner-download-list .item .item-btn {
    margin-top: 2rem;
    margin-inline: auto;
  }
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-download .partner-download-list .item:first-child {
    margin-top: 0;
  }
}
.l-main#partner .partner-download .partner-download-list .item:last-child .item__head {
  position: relative;
  top: 16px;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-download .partner-download-list .item:last-child .item__head {
    top: auto;
  }
}
.l-main#partner .partner-notlogin .partner-notlogin__head {
  font-size: 23px;
  font-weight: 500;
  line-height: 2;
  letter-spacing: 0em;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", serif;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-notlogin .partner-notlogin__head {
    font-size: 1.7rem;
  }
}
.l-main#partner .partner-notlogin .partner-notlogin__link {
  display: block;
  width: min(100%, 359px);
  font-size: 22px;
  font-weight: 500;
  line-height: 1.2727272727;
  letter-spacing: 0.3em;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", serif;
  text-align: center;
  padding: 0.95em 1em;
  margin-inline: auto;
  margin-top: 5rem;
  color: #fff;
  background: linear-gradient(to bottom, rgb(0, 129, 240), rgb(110, 210, 255));
  position: relative;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-notlogin .partner-notlogin__link {
    width: 85%;
    font-size: 1.6rem;
  }
}
.l-main#partner .partner-register {
  background-color: #F0F0F0;
}
.l-main#partner .partner-register .partner-register__container {
  padding-top: 193px;
  padding-bottom: 226px;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-register .partner-register__container {
    padding-top: 13rem;
    padding-bottom: 15rem;
  }
}
.l-main#partner .partner-register .wpmem_msg {
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0em;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", serif;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-register .wpmem_msg {
    font-size: 1.4rem;
  }
}
.l-main#partner .partner-register #wpmem_reg {
  width: 100%;
}
.l-main#partner .partner-register fieldset {
  margin: 0;
}
.l-main#partner .partner-register fieldset legend {
  display: none;
}
.l-main#partner .partner-register fieldset .text {
  font-size: 23px;
  font-weight: 500;
  line-height: 2;
  letter-spacing: 0em;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", serif;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-register fieldset .text {
    font-size: 1.7rem;
  }
}
.l-main#partner .partner-register fieldset .div_text {
  font-size: 23px;
  font-weight: 500;
  line-height: 2;
  letter-spacing: 0em;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", serif;
  position: relative;
  margin-bottom: 25px;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-register fieldset .div_text {
    font-size: 1.7rem;
    margin-bottom: 2rem;
  }
}
.l-main#partner .partner-register fieldset .div_text input {
  height: 50px;
  border: none;
  background-color: #fff;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-register fieldset .div_text input {
    height: 4.5rem;
  }
}
.l-main#partner .partner-register fieldset .button_div {
  width: min(100%, 359px);
  padding: 0;
  margin-top: 124px;
  margin-inline: auto;
  position: relative;
  font-size: 0;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-register fieldset .button_div {
    width: 85%;
    margin-top: 8rem;
  }
}
.l-main#partner .partner-register fieldset .button_div input[name=submit] {
  display: block;
  width: 100%;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.2727272727;
  letter-spacing: 0.3em;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", serif;
  text-align: center;
  padding: 0.95em 1em;
  margin-inline: auto;
  color: #fff;
  background: linear-gradient(to bottom, rgb(0, 129, 240), rgb(110, 210, 255));
  position: relative;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-register fieldset .button_div input[name=submit] {
    font-size: 1.6rem;
  }
}
.l-main#partner .partner-register fieldset .req-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.4285714286;
  letter-spacing: 0.05em;
  margin-top: 2em;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-register fieldset .req-text {
    font-size: 1.2rem;
  }
}
.l-main#partner .partner-register .register_status {
  font-size: 23px;
  font-weight: 500;
  line-height: 2;
  letter-spacing: 0em;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", serif;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-register .register_status {
    font-size: 1.7rem;
  }
}
.l-main#partner .partner-register .register_links {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: 0em;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", serif;
  text-align: center;
  color: #1E90FF;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-register .register_links {
    font-size: 1.6rem;
  }
}
.l-main#partner .partner-login {
  background-color: #F0F0F0;
}
.l-main#partner .partner-login .partner-login__container {
  padding-top: 193px;
  padding-bottom: 226px;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-login .partner-login__container {
    padding-top: 13rem;
    padding-bottom: 15rem;
  }
}
.l-main#partner .partner-login #wpmem_login {
  width: 100%;
}
.l-main#partner .partner-login fieldset {
  margin: 0;
}
.l-main#partner .partner-login fieldset legend {
  display: none;
}
.l-main#partner .partner-login fieldset .div_text {
  padding-left: 95px;
  position: relative;
  margin-bottom: 25px;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-login fieldset .div_text {
    padding-left: 7rem;
    margin-bottom: 2rem;
  }
}
.l-main#partner .partner-login fieldset .div_text::before {
  display: inline-block;
  font-size: 23px;
  font-weight: 500;
  line-height: 2;
  letter-spacing: 0em;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", serif;
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-login fieldset .div_text::before {
    font-size: 1.7rem;
    top: 0.5rem;
  }
}
.l-main#partner .partner-login fieldset .div_text:nth-of-type(1)::before {
  content: "ID";
}
.l-main#partner .partner-login fieldset .div_text:nth-of-type(2)::before {
  content: "PASS";
}
.l-main#partner .partner-login fieldset .div_text input {
  height: 50px;
  border: none;
  background-color: #fff;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-login fieldset .div_text input {
    height: 4.5rem;
  }
}
.l-main#partner .partner-login fieldset label[for=log] {
  display: none;
}
.l-main#partner .partner-login fieldset label[for=pwd] {
  display: none;
}
.l-main#partner .partner-login fieldset input[name=rememberme] {
  display: none;
}
.l-main#partner .partner-login fieldset label[for=rememberme] {
  display: none;
}
.l-main#partner .partner-login fieldset .button_div {
  width: min(100%, 359px);
  padding: 0;
  margin-top: 124px;
  margin-inline: auto;
  position: relative;
  font-size: 0;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-login fieldset .button_div {
    width: 85%;
    margin-top: 8rem;
  }
}
.l-main#partner .partner-login fieldset .button_div::after {
  content: "送　信";
  display: inline-block;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.2727272727;
  letter-spacing: 0.3em;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", serif;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  pointer-events: none;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-login fieldset .button_div::after {
    font-size: 1.6rem;
  }
}
.l-main#partner .partner-login fieldset .button_div input[name=Submit] {
  display: block;
  width: 100%;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.2727272727;
  letter-spacing: 0.3em;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", serif;
  text-align: center;
  padding: 0.95em 1em;
  margin-inline: auto;
  color: transparent;
  background: linear-gradient(to bottom, rgb(0, 129, 240), rgb(110, 210, 255));
  position: relative;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-login fieldset .button_div input[name=Submit] {
    font-size: 1.6rem;
  }
}
.l-main#partner .partner-login fieldset .link-text {
  display: none;
}
.l-main#partner .partner-login .login_status {
  font-size: 16px;
  font-weight: 500;
  line-height: 2;
  letter-spacing: 0.05em;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-login .login_status {
    font-size: 1.4rem;
  }
}
.l-main#partner .partner-login .login_status a {
  color: #1E90FF;
}
.l-main#partner .partner-profile {
  background-color: #F0F0F0;
}
.l-main#partner .partner-profile .partner-profile__container {
  padding-top: 193px;
  padding-bottom: 226px;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-profile .partner-profile__container {
    padding-top: 13rem;
    padding-bottom: 15rem;
  }
}
.l-main#partner .partner-profile #wpmem_login {
  width: 100%;
}
.l-main#partner .partner-profile #wpmem_login fieldset {
  margin: 0;
}
.l-main#partner .partner-profile #wpmem_login fieldset .div_text {
  padding-left: 95px;
  position: relative;
  margin-bottom: 25px;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-profile #wpmem_login fieldset .div_text {
    padding-left: 7rem;
    margin-bottom: 2rem;
  }
}
.l-main#partner .partner-profile #wpmem_login fieldset .div_text::before {
  display: inline-block;
  font-size: 23px;
  font-weight: 500;
  line-height: 2;
  letter-spacing: 0em;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", serif;
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-profile #wpmem_login fieldset .div_text::before {
    font-size: 1.7rem;
    top: 0.5rem;
  }
}
.l-main#partner .partner-profile #wpmem_login fieldset .div_text:nth-of-type(1)::before {
  content: "ID";
}
.l-main#partner .partner-profile #wpmem_login fieldset .div_text:nth-of-type(2)::before {
  content: "PASS";
}
.l-main#partner .partner-profile #wpmem_login fieldset .div_text input {
  height: 50px;
  border: none;
  background-color: #fff;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-profile #wpmem_login fieldset .div_text input {
    height: 4.5rem;
  }
}
.l-main#partner .partner-profile #wpmem_login fieldset .div_text:has(#pass1):nth-of-type(1)::before {
  content: "PASS";
}
.l-main#partner .partner-profile #wpmem_login fieldset label[for=log] {
  display: none;
}
.l-main#partner .partner-profile #wpmem_login fieldset label[for=pwd] {
  display: none;
}
.l-main#partner .partner-profile #wpmem_login fieldset input[name=rememberme] {
  display: none;
}
.l-main#partner .partner-profile #wpmem_login fieldset label[for=rememberme] {
  display: none;
}
.l-main#partner .partner-profile #wpmem_login fieldset .button_div {
  width: min(100%, 359px);
  padding: 0;
  margin-top: 50px;
  margin-inline: auto;
  position: relative;
  font-size: 0;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-profile #wpmem_login fieldset .button_div {
    width: 85%;
    margin-top: 4rem;
  }
}
.l-main#partner .partner-profile #wpmem_login fieldset .button_div::after {
  content: "送　信";
  display: inline-block;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.2727272727;
  letter-spacing: 0.3em;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", serif;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  pointer-events: none;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-profile #wpmem_login fieldset .button_div::after {
    font-size: 1.6rem;
  }
}
.l-main#partner .partner-profile #wpmem_login fieldset .button_div input[name=Submit] {
  display: block;
  width: 100%;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.2727272727;
  letter-spacing: 0.3em;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", serif;
  text-align: center;
  padding: 0.95em 1em;
  margin-inline: auto;
  color: transparent;
  background: linear-gradient(to bottom, rgb(0, 129, 240), rgb(110, 210, 255));
  position: relative;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-profile #wpmem_login fieldset .button_div input[name=Submit] {
    font-size: 1.6rem;
  }
}
.l-main#partner .partner-profile #wpmem_login fieldset .link-text {
  display: none;
}
.l-main#partner .partner-profile #wpmem_reg {
  width: 100%;
  margin-top: 10em;
}
.l-main#partner .partner-profile #wpmem_reg fieldset {
  margin: 0;
}
.l-main#partner .partner-profile #wpmem_reg fieldset .text {
  font-size: 23px;
  font-weight: 500;
  line-height: 2;
  letter-spacing: 0em;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", serif;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-profile #wpmem_reg fieldset .text {
    font-size: 1.7rem;
  }
}
.l-main#partner .partner-profile #wpmem_reg fieldset .div_text {
  font-size: 23px;
  font-weight: 500;
  line-height: 2;
  letter-spacing: 0em;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", serif;
  position: relative;
  margin-bottom: 25px;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-profile #wpmem_reg fieldset .div_text {
    font-size: 1.7rem;
    margin-bottom: 2rem;
  }
}
.l-main#partner .partner-profile #wpmem_reg fieldset .div_text input {
  height: 50px;
  border: none;
  background-color: #fff;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-profile #wpmem_reg fieldset .div_text input {
    height: 4.5rem;
  }
}
.l-main#partner .partner-profile #wpmem_reg fieldset .button_div {
  width: min(100%, 359px);
  padding: 0;
  margin-top: 50px;
  margin-inline: auto;
  position: relative;
  font-size: 0;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-profile #wpmem_reg fieldset .button_div {
    width: 85%;
    margin-top: 4rem;
  }
}
.l-main#partner .partner-profile #wpmem_reg fieldset .button_div input[name=submit] {
  display: block;
  width: 100%;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.2727272727;
  letter-spacing: 0.3em;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", serif;
  text-align: center;
  padding: 0.95em 1em;
  margin-inline: auto;
  color: #fff;
  background: linear-gradient(to bottom, rgb(0, 129, 240), rgb(110, 210, 255));
  position: relative;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-profile #wpmem_reg fieldset .button_div input[name=submit] {
    font-size: 1.6rem;
  }
}
.l-main#partner .partner-profile #wpmem_reg fieldset .req-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.4285714286;
  letter-spacing: 0.05em;
  margin-top: 2em;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-profile #wpmem_reg fieldset .req-text {
    font-size: 1.2rem;
  }
}
.l-main#partner .partner-profile ul li {
  font-size: 16px;
  font-weight: 500;
  line-height: 2;
  letter-spacing: 0.05em;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .l-main#partner .partner-profile ul li {
    font-size: 1.4rem;
  }
}
.l-main#partner .partner-profile ul li a {
  color: #1E90FF;
}