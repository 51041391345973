@charset "UTF-8";
@use "../global" as *;

.l-main#partner {
    .partner {
        .m-fv {
            background-image: url(../../img/partner/fv.jpg);
        }
    }
    .partner__area {
        padding-top: 150px;
        padding-bottom: 262px;
        background-color: $color-gray3;
        @include bpTb {
            padding-top: 10rem;
            padding-bottom: 17.5rem;
        }
    }
    .partner-news {
        padding-bottom: 160px;
        @include bpTb {
            padding-bottom: 10rem;
        }
        .partner-news__title {
            font-size: $text-27;
            @include font(27, 400, 50, 300);
            text-align: center;
            @include bpTb {
                font-size: $text-27-sp;
            }
        }
        .partner-news-list {
            margin-top: 40px;
            @include bpTb {
                margin-top: 4rem;
            }
            .partner-news-list__item {
                &:last-child {
                    .link {
                        border-bottom: 0.1rem solid $whiteColor;
                    }
                }
            }
            .link {
                display: flex;
                align-items: flex-start;
                padding: 23px 26px 28px;
                border-top: 0.1rem solid $whiteColor;
                @include bpTb {
                    display: block;
                    padding: 1.5rem 1.5rem 2rem;
                }
            }
            .partner-news-list__time {
                display: block;
                width: min(100%, 132px);
                font-size: $text-14;
                @include font(14, 400, 21, 50);
                margin-top: 0.2em;
                color: $color-blue4;
                @include bpTb {
                    width: auto;
                    font-size: $text-14-sp;
                }
            }
            .partner-news-list__title {
                flex: 1;
                font-size: $text-16;
                @include font(16, 400, 24, 50);
                @include bpTb {
                    font-size: $text-16-sp;
                    margin-top: 0.5em;
                }
            }
        }
        .partner-news__post {
            font-size: $text-16;
            @include font(16, 400, 24, 50);
            text-align: center;
            margin-top: 5rem;
            @include bpTb {
                font-size: $text-16-sp;
            }
        }
    }
    .partner-download {
        .m-title1 {
            
        }
        .partner-download-list {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            padding-top: 89px;
            @include bpTb {
                display: block;
                padding-top: 7rem;
            }
            .item {
                width: 29%;
                @include bpTb {
                    width: 100%;
                    margin-top: 5rem;
                }
                .item__head {
                    font-size: $text-21;
                    @include font(21, 400, 35, 300);
                    text-align: center;
                    @include bpTb {
                        font-size: $text-21-sp;
                    }
                }
                .item__icon {
                    text-align: center;
                    margin-top: 52px;
                    @include bpTb {
                        margin-top: 2rem;
                    }
                }
                .item-btn {
                    margin-top: 36px;
                    @include bpTb {
                        margin-top: 2rem;
                        margin-inline: auto;
                    }
                }
                &:first-child {
                    @include bpTb {
                        margin-top: 0;
                    }
                }
                &:last-child {
                    .item__head {
                        position: relative;
                        top: 16px;
                        @include bpTb {
                            top: auto;
                        }
                    }
                }
            }
        }
    }
    .partner-notlogin {
        .partner-notlogin__head {
            font-size: $text-23;
            @include font(23, 500, 46, 0);
            font-family: $mincho;
            text-align: center;
            @include bpTb {
                font-size: $text-23-sp;
            }
        }
        .partner-notlogin__link {
            display: block;
            width: min(100%, 359px);
            font-size: $text-22;
            @include font(22, 500, 28, 300);
            font-family: $mincho;
            text-align: center;
            padding: 0.95em 1em;
            margin-inline: auto;
            margin-top: 5rem;
            color: $whiteColor;
            background: linear-gradient(to bottom, rgb(0, 129, 240), rgb(110, 210, 255));
            position: relative;
            @include bpTb {
                width: 85%;
                font-size: $text-22-sp;
            }
        }
    }
    // 新規会員登録ページ
    .partner-register {
        background-color: $color-gray3;
        .partner-register__container {
            padding-top: 193px;
            padding-bottom: 226px;
            @include bpTb {
                padding-top: 13rem;
                padding-bottom: 15rem;
            }
        }
        .wpmem_msg {
            width: 100%;
            font-size: $text-16;
            @include font(16, 500, 24, 0);
            font-family: $mincho;
            @include bpTb {
                font-size: $text-16-sp;
            }
        }
        #wpmem_reg {
            width: 100%;
        }
        // ログイン前
        fieldset {
            margin: 0;
            legend {
                display: none;
            }
            .text {
                font-size: $text-23;
                @include font(23, 500, 46, 0);
                font-family: $mincho;
                @include bpTb {
                    font-size: $text-23-sp;
                }
            }
            .div_text {
                font-size: $text-23;
                @include font(23, 500, 46, 0);
                font-family: $mincho;
                position: relative;
                margin-bottom: 25px;
                @include bpTb {
                    font-size: $text-23-sp;
                    margin-bottom: 2rem;
                }
                input {
                    height: 50px;
                    border: none;
                    background-color: $whiteColor;
                    @include bpTb {
                        height: 4.5rem;
                    }
                }
            }
            .button_div {
                width: min(100%, 359px);
                padding: 0;
                margin-top: 124px;
                margin-inline: auto;
                position: relative;
                font-size: 0;
                @include bpTb {
                    width: 85%;
                    margin-top: 8rem;
                }
                input[name="submit"] {
                    display: block;
                    width: 100%;
                    font-size: $text-22;
                    @include font(22, 500, 28, 300);
                    font-family: $mincho;
                    text-align: center;
                    padding: 0.95em 1em;
                    margin-inline: auto;
                    color: $whiteColor;
                    background: linear-gradient(to bottom, rgb(0, 129, 240), rgb(110, 210, 255));
                    position: relative;
                    @include bpTb {
                        font-size: $text-22-sp;
                    }
                }
            }
            .req-text {
                font-size: $text-14;
                @include font(14, 500, 20, 50);
                margin-top: 2em;
                @include bpTb {
                    font-size: $text-14-sp;
                }
            }
        }
        // ログイン後
        .register_status {
            font-size: $text-23;
            @include font(23, 500, 46, 0);
            font-family: $mincho;
            text-align: center;
            @include bpTb {
                font-size: $text-23-sp;
            }
        }
        .register_links {
            font-size: $text-20;
            @include font(20, 500, 35, 0);
            font-family: $mincho;
            text-align: center;
            color: $color-blue2;
            @include bpTb {
                font-size: $text-20-sp;
            }
        }
    }
    // ログインページ
    .partner-login {
        background-color: $color-gray3;
        .partner-login__container {
            padding-top: 193px;
            padding-bottom: 226px;
            @include bpTb {
                padding-top: 13rem;
                padding-bottom: 15rem;
            }
        }
        #wpmem_login {
            width: 100%;
        }
        // ログイン前
        fieldset {
            margin: 0;
            legend {
                display: none;
            }
            .div_text {
                padding-left: 95px;
                position: relative;
                margin-bottom: 25px;
                @include bpTb {
                    padding-left: 7rem;
                    margin-bottom: 2rem;
                }
                &::before {
                    display: inline-block;
                    font-size: $text-23;
                    @include font(23, 500, 46, 0);
                    font-family: $mincho;
                    position: absolute;
                    top: 0;
                    left: 0;
                    @include bpTb {
                        font-size: $text-23-sp;
                        top: 0.5rem;
                    }
                }
                &:nth-of-type(1) {
                    &::before {
                        content: 'ID';
                    }
                }
                &:nth-of-type(2) {
                    &::before {
                        content: 'PASS';
                    }
                }
                input {
                    height: 50px;
                    border: none;
                    background-color: $whiteColor;
                    @include bpTb {
                        height: 4.5rem;
                    }
                }
            }
            label[for="log"] {
                display: none;
            }
            label[for="pwd"] {
                display: none;
            }
            input[name="rememberme"] {
                display: none;
            }
            label[for="rememberme"] {
                display: none;
            }
            .button_div {
                width: min(100%, 359px);
                padding: 0;
                margin-top: 124px;
                margin-inline: auto;
                position: relative;
                font-size: 0;
                @include bpTb {
                    width: 85%;
                    margin-top: 8rem;
                }
                &::after {
                    content: '送　信';
                    display: inline-block;
                    font-size: $text-22;
                    @include font(22, 500, 28, 300);
                    font-family: $mincho;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    color: $whiteColor;
                    pointer-events: none;
                    @include bpTb {
                        font-size: $text-22-sp;
                    }
                }
                input[name="Submit"] {
                    display: block;
                    width: 100%;
                    font-size: $text-22;
                    @include font(22, 500, 28, 300);
                    font-family: $mincho;
                    text-align: center;
                    padding: 0.95em 1em;
                    margin-inline: auto;
                    color: transparent;
                    background: linear-gradient(to bottom, rgb(0, 129, 240), rgb(110, 210, 255));
                    position: relative;
                    @include bpTb {
                        font-size: $text-22-sp;
                    }
                }
            }
            .link-text {
                display: none;
            }
        }
        // ログイン後
        .login_status {
            font-size: $text-16;
            @include font(16, 500, 32, 50);
            text-align: center;
            @include bpTb {
                font-size: $text-16-sp;
            }
            a {
                color: $color-blue2;
            }
        }
    }
    // プロフィールページ
    .partner-profile {
        background-color: $color-gray3;
        .partner-profile__container {
            padding-top: 193px;
            padding-bottom: 226px;
            @include bpTb {
                padding-top: 13rem;
                padding-bottom: 15rem;
            }
        }
        #wpmem_login {
            width: 100%;
            fieldset {
                margin: 0;
                .div_text {
                    padding-left: 95px;
                    position: relative;
                    margin-bottom: 25px;
                    @include bpTb {
                        padding-left: 7rem;
                        margin-bottom: 2rem;
                    }
                    &::before {
                        display: inline-block;
                        font-size: $text-23;
                        @include font(23, 500, 46, 0);
                        font-family: $mincho;
                        position: absolute;
                        top: 0;
                        left: 0;
                        @include bpTb {
                            font-size: $text-23-sp;
                            top: 0.5rem;
                        }
                    }
                    &:nth-of-type(1) {
                        &::before {
                            content: 'ID';
                        }
                    }
                    &:nth-of-type(2) {
                        &::before {
                            content: 'PASS';
                        }
                    }
                    input {
                        height: 50px;
                        border: none;
                        background-color: $whiteColor;
                        @include bpTb {
                            height: 4.5rem;
                        }
                    }
                    &:has(#pass1) {
                        &:nth-of-type(1) {
                            &::before {
                                content: 'PASS';
                            }
                        }
                    }
                }
                label[for="log"] {
                    display: none;
                }
                label[for="pwd"] {
                    display: none;
                }
                input[name="rememberme"] {
                    display: none;
                }
                label[for="rememberme"] {
                    display: none;
                }
                .button_div {
                    width: min(100%, 359px);
                    padding: 0;
                    margin-top: 50px;
                    margin-inline: auto;
                    position: relative;
                    font-size: 0;
                    @include bpTb {
                        width: 85%;
                        margin-top: 4rem;
                    }
                    &::after {
                        content: '送　信';
                        display: inline-block;
                        font-size: $text-22;
                        @include font(22, 500, 28, 300);
                        font-family: $mincho;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        color: $whiteColor;
                        pointer-events: none;
                        @include bpTb {
                            font-size: $text-22-sp;
                        }
                    }
                    input[name="Submit"] {
                        display: block;
                        width: 100%;
                        font-size: $text-22;
                        @include font(22, 500, 28, 300);
                        font-family: $mincho;
                        text-align: center;
                        padding: 0.95em 1em;
                        margin-inline: auto;
                        color: transparent;
                        background: linear-gradient(to bottom, rgb(0, 129, 240), rgb(110, 210, 255));
                        position: relative;
                        @include bpTb {
                            font-size: $text-22-sp;
                        }
                    }
                }
                .link-text {
                    display: none;
                }
            }
        }
        #wpmem_reg {
            width: 100%;
            margin-top: 10em;
            fieldset {
                margin: 0;
                .text {
                    font-size: $text-23;
                    @include font(23, 500, 46, 0);
                    font-family: $mincho;
                    @include bpTb {
                        font-size: $text-23-sp;
                    }
                }
                .div_text {
                    font-size: $text-23;
                    @include font(23, 500, 46, 0);
                    font-family: $mincho;
                    position: relative;
                    margin-bottom: 25px;
                    @include bpTb {
                        font-size: $text-23-sp;
                        margin-bottom: 2rem;
                    }
                    input {
                        height: 50px;
                        border: none;
                        background-color: $whiteColor;
                        @include bpTb {
                            height: 4.5rem;
                        }
                    }
                }
                .button_div {
                    width: min(100%, 359px);
                    padding: 0;
                    margin-top: 50px;
                    margin-inline: auto;
                    position: relative;
                    font-size: 0;
                    @include bpTb {
                        width: 85%;
                        margin-top: 4rem;
                    }
                    input[name="submit"] {
                        display: block;
                        width: 100%;
                        font-size: $text-22;
                        @include font(22, 500, 28, 300);
                        font-family: $mincho;
                        text-align: center;
                        padding: 0.95em 1em;
                        margin-inline: auto;
                        color: $whiteColor;
                        background: linear-gradient(to bottom, rgb(0, 129, 240), rgb(110, 210, 255));
                        position: relative;
                        @include bpTb {
                            font-size: $text-22-sp;
                        }
                    }
                }
                .req-text {
                    font-size: $text-14;
                    @include font(14, 500, 20, 50);
                    margin-top: 2em;
                    @include bpTb {
                        font-size: $text-14-sp;
                    }
                }
            }
        }
        ul li {
            font-size: $text-16;
            @include font(16, 500, 32, 50);
            text-align: center;
            @include bpTb {
                font-size: $text-16-sp;
            }
            a {
                color: $color-blue2;
            }
        }
    }
}